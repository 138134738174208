import {
  useState,
  SyntheticEvent,
  MouseEventHandler,
  forwardRef,
  useEffect,
  CSSProperties,
  PropsWithChildren,
  RefObject,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring';
import { getDocument } from 'ssr-window';

import { useBlockScroll, useMounted } from '@zaritalk/hooks/index';
import { css as pandaCss } from '@zaritalk/panda-css/css';
import { styled } from '@zaritalk/panda-css/jsx';
import { SystemStyleObject } from '@zaritalk/panda-css/types';

import { Button, Icon, Layout } from './';
import { ButtonVariants } from './Button/BasicButton';

interface PopupProps {
  isOpen: boolean;
  beforeFocusRef?: RefObject<HTMLElement>;
  onDismiss?: (event?: SyntheticEvent) => void;
  isScrollYOverFlow?: boolean;
}

interface PopupContainerProps extends PopupProps {
  children: JSX.Element | JSX.Element[];
  css?: SystemStyleObject;
}

function Container({
  isOpen,
  onDismiss,
  beforeFocusRef,
  children,
  isScrollYOverFlow = false,
  css,
}: PopupContainerProps) {
  const { mounted } = useMounted();
  const [openState, setOpenState] = useState<boolean>(isOpen);
  const [animationState, setAnimationState] = useState<boolean>(isOpen);

  const [overlayStyles, api] = useSpring(() => ({
    opacity: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(34, 34, 34, 0.5)',
  }));

  api.start({ opacity: animationState ? 1 : 0 });

  const closePopup = () => {
    setAnimationState(false);
  };

  useEffect(() => {
    setAnimationState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!animationState) {
      const timer = setTimeout(() => {
        setOpenState(false);
        isOpen && onDismiss && onDismiss();
      }, 200);
      return () => clearTimeout(timer);
    } else {
      return setOpenState(true);
    }
  }, [isOpen, animationState]);

  useEffect(() => {
    const $rootNode = document.getElementById('__next');
    $rootNode?.setAttribute('aria-hidden', 'true');

    const handleCloseModalWithEscHandler = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        setOpenState(false);
        setAnimationState(false);
      }
    };

    window.addEventListener('keyup', handleCloseModalWithEscHandler);

    const beforeRefSnapshot = beforeFocusRef;

    return () => {
      $rootNode?.removeAttribute('aria-hidden');

      window.removeEventListener('keyup', handleCloseModalWithEscHandler);

      beforeRefSnapshot?.current?.focus();
    };
  }, [mounted, beforeFocusRef]);

  return (
    <>
      {mounted && openState ? (
        createPortal(
          <PopupContainer onClick={(e) => e.stopPropagation()} css={css}>
            <animated.div style={overlayStyles} onClick={closePopup} />
            <AnimationDiv show={animationState} isScrollYOverFlow={isScrollYOverFlow}>
              {children}
            </AnimationDiv>
          </PopupContainer>,
          getDocument().getElementsByTagName('body')[0],
        )
      ) : (
        <></>
      )}
    </>
  );
}

interface PopupHeaderProps {
  title: string;
}

function Header({ title }: PopupHeaderProps) {
  return (
    <Layout.RowCenter>
      <PopupHeaderTitle>{title}</PopupHeaderTitle>
    </Layout.RowCenter>
  );
}

interface PopupContentsProps extends PropsWithChildren {
  css?: SystemStyleObject;
}

const Contents = forwardRef<HTMLElement, PopupContentsProps>(({ css, children }, ref) => {
  useBlockScroll();

  return (
    <PopupContents css={css} ref={ref}>
      {children}
    </PopupContents>
  );
});

const NoSpaceContents = forwardRef<HTMLElement, PopupContentsProps>(({ css, children }, ref) => {
  useBlockScroll();

  return (
    <PopupContents css={css} ref={ref} noSpace>
      {children}
    </PopupContents>
  );
});

const SmallSizeContents = forwardRef<HTMLElement, PopupContentsProps>(({ css, children }, ref) => {
  useBlockScroll();

  return (
    <PopupContents ref={ref} size={'small'} css={css}>
      {children}
    </PopupContents>
  );
});

const SmallSizeNoSpaceContents = forwardRef<HTMLElement, PopupContentsProps>(({ css, children }, ref) => {
  useBlockScroll();

  return (
    <PopupContents css={css} ref={ref} size={'small'} noSpace>
      {children}
    </PopupContents>
  );
});

const FullSizeContents = forwardRef<HTMLElement, PopupContentsProps>(({ css, children }, ref) => {
  useBlockScroll();

  return (
    <PopupContents css={css} ref={ref} size={'full'}>
      {children}
    </PopupContents>
  );
});

interface CloseIconProps {
  onClick?: (event?: SyntheticEvent) => void;
}

function CloseIcon({ onClick }: CloseIconProps) {
  return <Icon.CloseIcon className={closeBtn} onClick={() => (onClick ? onClick() : () => ({}))} />;
}

type PortalFooterType = 'NONE' | 'ONE' | 'TWO' | 'TWO_REVERSE';

interface PopupFooterProps {
  buttonSize?: ButtonVariants['size'];
  type: PortalFooterType;
  successTitle?: string;
  successCallback?: MouseEventHandler;
  successLoading?: boolean;

  failTitle?: string;
  failCallback?: MouseEventHandler;
  failLoading?: boolean;

  successDisable?: boolean;
  failDisable?: boolean;
  successStyle?: CSSProperties;
  failStyle?: CSSProperties;
}

function Footer({
  type,
  successTitle = '',
  successCallback,
  successLoading,
  failTitle = '',
  failCallback,
  failLoading,
  successDisable,
  failDisable,
  successStyle,
  failStyle,
  buttonSize = 'small',
}: PopupFooterProps) {
  if (type === 'ONE') {
    return (
      <Button
        size={buttonSize}
        fullWidth={true}
        variant={'PRIMARY'}
        css={{ width: '100%', zIndex: 100 }}
        isLoading={successLoading || failLoading}
        onClick={successCallback || failCallback}
        disabled={successDisable || failDisable}
        style={successStyle || failStyle}
      >
        {successTitle || failTitle}
      </Button>
    );
  } else if (type === 'TWO') {
    return (
      <PopupTwoBtnC>
        <Button
          size={buttonSize}
          fullWidth={true}
          variant={'SECONDARY'}
          css={{ width: '50%', marginRight: '8px', zIndex: 100 }}
          isLoading={failLoading}
          onClick={failCallback}
          disabled={failDisable}
          style={failStyle}
        >
          {failTitle}
        </Button>
        <Button
          size={buttonSize}
          fullWidth={true}
          variant={'PRIMARY'}
          css={{ width: '50%', zIndex: 100 }}
          isLoading={successLoading}
          onClick={successCallback}
          disabled={successDisable}
          style={successStyle}
        >
          {successTitle}
        </Button>
      </PopupTwoBtnC>
    );
  } else if (type === 'TWO_REVERSE') {
    return (
      <PopupTwoBtnC>
        <Button
          size={buttonSize}
          fullWidth={true}
          variant={'PRIMARY'}
          css={{ width: '50%', marginRight: '8px', zIndex: 100 }}
          isLoading={successLoading}
          onClick={successCallback}
          disabled={successDisable}
          style={successStyle}
        >
          {successTitle}
        </Button>
        <Button
          size={buttonSize}
          fullWidth={true}
          variant={'SECONDARY'}
          css={{ width: '50%', zIndex: 100 }}
          isLoading={failLoading}
          onClick={failCallback}
          disabled={failDisable}
          style={failStyle}
        >
          {failTitle}
        </Button>
      </PopupTwoBtnC>
    );
  }

  return null;
}

const usePopupToggle = (
  defaultToggleValue: boolean,
  closeCallback?: VoidFunction,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isOpenPopup, setIsOpenPopup] = useState(defaultToggleValue);
  const prevOpenState = useRef<boolean>(defaultToggleValue);

  useEffect(() => {
    if (prevOpenState.current && !isOpenPopup) {
      const timer = setTimeout(() => {
        closeCallback && closeCallback();
      }, 200);
      return () => clearTimeout(timer);
    }

    prevOpenState.current = isOpenPopup;
    return;
  }, [isOpenPopup]);

  return [isOpenPopup, setIsOpenPopup];
};

export {
  Container,
  Header,
  Contents,
  NoSpaceContents,
  SmallSizeContents,
  SmallSizeNoSpaceContents,
  FullSizeContents,
  Footer,
  CloseIcon,
  usePopupToggle,
  type PopupProps,
};

const AnimationDiv = styled('div', {
  base: {
    position: 'fixed',
    bottom: '45%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translateX(-50%) translateY(50%)',
    opacity: 1,
    animation: 'popupOpen 0.2s cubic-bezier(0.645, 0.045, 0.355, 1.000) 0s forwards running',
  },

  variants: {
    show: {
      false: {
        animation: 'popupClose 0.2s cubic-bezier(0.645, 0.045, 0.355, 1.000) 0s forwards running',
      },
    },
    isScrollYOverFlow: {
      true: {
        overflowY: 'scroll',
      },
    },
  },
});

const PopupContainer = styled('div', {
  base: {
    position: 'fixed',
    top: 0,
    left: 0,

    width: '100%',
    height: '100%',

    margin: 0,
    padding: 0,

    zIndex: 20000,
  },
});

const PopupHeaderTitle = styled('p', {
  base: {
    padding: '4px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

const PopupContents = styled('section', {
  base: {
    position: 'absolute',
    top: '50%',
    left: '50%',

    maxWidth: '480px',
    width: '100%',
    maxHeight: '95vh',

    overflow: 'scroll',

    padding: '16px',

    borderRadius: '8px',

    backgroundColor: '$white',
    boxShadow: '0 0 14px 3px rgba(34, 34, 34, 0.1)',

    transform: 'translate(-50%, -50%)',

    '@media screen and (max-width: 640px)': {
      width: 'calc(100% - 48px)',
    },
  },

  variants: {
    size: {
      full: {
        top: '0',
        left: '0',

        maxWidth: '100%',
        height: '100vh',
        maxHeight: '100vh',

        borderRadius: '0',
        boxShadow: 'none',

        transform: 'translate(0, 0)',
        padding: '0',
        overflow: 'auto',

        '@media screen and (max-width: 640px)': {
          width: '100%',
        },
      },
      small: {
        maxWidth: '312px',
      },
    },
    noSpace: {
      true: {
        padding: '0',
      },
    },
  },
});

const PopupTwoBtnC = styled('section', {
  base: {
    flexRow: 'CENTER',
    width: '100%',
  },
});

const closeBtn = pandaCss({
  position: 'absolute',
  top: '12px',
  right: '12px',

  cursor: 'pointer',
});
