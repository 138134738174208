import { useState } from 'react';

import { useLessorDetail } from '@zaritalk/api';
import { COLOR } from '@zaritalk/constants';
import { styled } from '@zaritalk/panda-css/jsx';
import { Icon, Popup, Typography } from '@zaritalk/ui-components/index';

export default function ZaritalkQATools() {
  const [isOpenQaTools, setIsOpenQaTools] = useState<boolean>(false);

  const { data: lessorDetail } = useLessorDetail();

  return (
    <>
      <QAToolsButton type={'button'} onClick={() => setIsOpenQaTools(true)}>
        <Icon.LogoFillIcon height={'18px'} fill={COLOR.PRIMARY01} />
      </QAToolsButton>

      <Popup.Container isOpen={isOpenQaTools} css={{ zIndex: 9999999 }}>
        <Popup.SmallSizeContents css={{ flexCol: 'START_START', gap: '6px' }}>
          <QAToolsInfo variant={'12_BOLD_EXTRA_NARROW'}>
            {'Current Env: '}
            <em>{process.env.API_ENV}</em>
          </QAToolsInfo>
          <QAToolsInfo variant={'12_BOLD_EXTRA_NARROW'}>
            {'Lessor PK: '}
            <em>{lessorDetail?.lessorPK ?? 'Please Login'}</em>
          </QAToolsInfo>
          <QAToolsInfo variant={'12_BOLD_EXTRA_NARROW'}>
            {'Admin: '}
            <em>{`${lessorDetail?.admin ?? 'Please Login'}`}</em>
          </QAToolsInfo>
          <CloseButton height={'18px'} onClick={() => setIsOpenQaTools(false)} />
        </Popup.SmallSizeContents>
      </Popup.Container>
    </>
  );
}

const QAToolsButton = styled('button', {
  base: {
    position: 'fixed',
    top: '50%',
    left: '3px',
    padding: '5px',
    width: '30px',
    height: '30px',
    backgroundColor: '$gray05',
    opacity: 0.6,
    border: 0,
    borderRadius: '4px',
    zIndex: 9999999,
  },
});

const CloseButton = styled(Icon.CloseIcon, {
  base: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
});

const QAToolsInfo = styled(Typography, {
  base: {
    '& > em': {
      color: '$primary01',
    },
  },
});
