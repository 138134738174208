import { memo, ReactElement } from 'react';

import { SVGIconProps } from '../';

const LogoFillIcon = memo(
  ({ fill = '#2c7fff', height = '28px', className, onClick }: SVGIconProps): ReactElement => (
    <svg
      className={className}
      height={height}
      fill={'none'}
      onClick={onClick}
      viewBox={'0 0 28 28'}
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M16.9233 27H11.0767C5.51157 27 1 22.489 1 16.9233V11.0767C1 5.511 5.51157 1 11.0767 1H16.9233C22.489 1 27 5.511 27 11.0767V16.9233C27 22.489 22.489 27 16.9233 27'
        }
        fill={fill}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M19.087 15.079C19.087 15.4198 18.8112 15.6956 18.4704 15.6956H18.008C17.6672 15.6956 17.3914 15.4198 17.3914 15.079V12.921C17.3914 12.5801 17.6672 12.3043 18.008 12.3043H18.4704C18.8112 12.3043 19.087 12.5801 19.087 12.921V15.079ZM15.1305 15.0773C15.1305 15.4187 14.8535 15.6956 14.5121 15.6956H14.0532C13.7118 15.6956 13.4348 15.4187 13.4348 15.0773V12.9227C13.4348 12.5807 13.7118 12.3043 14.0532 12.3043H14.5121C14.8535 12.3043 15.1305 12.5807 15.1305 12.9227V15.0773ZM21.0133 10.7788L14.8558 6.44809C14.3878 5.96652 13.6123 5.96652 13.1443 6.44809L6.98683 10.7788C6.77205 10.9998 6.65222 11.296 6.65222 11.6034V18.5217V19.031V21.6733C6.65222 21.8723 6.89131 21.9842 7.05522 21.8621L9.18722 20.2173H20.1587C20.8154 20.2173 21.3479 19.686 21.3479 19.031V11.6034C21.3479 11.296 21.228 10.9998 21.0133 10.7788V10.7788Z'
        }
        fill={'white'}
      />
    </svg>
  ),
);

export default LogoFillIcon;
