import { useEffect, useState } from 'react';

const useMounted = (callback?: (() => () => void) | (() => void)) => {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);

    if (typeof callback === 'function') {
      const callbackReturn = callback();

      if (typeof callbackReturn === 'function') {
        return callbackReturn();
      }
    }
  }, [callback]);

  return { mounted };
};

export default useMounted;
